<template>
  <div>
    <!-- 듀얼카메라 UI -->
    <template v-if="device.service_type=='srds.dual'">
      <CRow>
        <CCol col="12" xl="6" >
            <CCard>
                <div class="title">{{ screens[0].title }}</div>
                <CCardBody>
                        <div id="custCarousel" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000" align="center" style="border:1px solid #eee; border-radius:1%; height:100%; ">
                            <!-- 메인 슬라이드 이미지 slides -->
                            <div class="carousel-inner">
                                <div class="carousel-item" v-for="(img, index) in screens[0].deviceImages" :key="index" :class="{ 'active': index === 0 }">
                                  <img :src="img.url" style="max-height: 360px; cursor: pointer" @click="openUploadDialog()">
                                  <div class="row" id="main-img-caption">
                                      <div class="col-sm-12">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="22" width="16" viewBox="0 0 512 500"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                                          <br />
                                          <span class="time"> {{img.created_at_thumb}} <br> {{ img.created_at_time }}</span>                  
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <!-- 화살표 indicator -->
                            <a class="carousel-control-prev" href="#custCarousel" data-slide="prev">
                                <i class="fa fa-chevron-left" aria-hidden="true" style="color:#848484;font-size:xxx-large;font-weight:800"></i>
                            </a>
                            <a class="carousel-control-next" href="#custCarousel" data-slide="next">
                                <i class="fa fa-chevron-right" aria-hidden="true" style="color:#848484;font-size:xxx-large;;font-weight:800"></i>
                            </a>
                            <!-- 썸네일 Thumnails -->
                            <div class="carousel-indicators-container">
                                <div class="carousel-indicators-wrapper">
                                  <ol class="carousel-indicators list-inline">  <!--  hj :: for spacing add "me-4" at the end of the class -->
                                      <li class="list-inline-item" v-for="(img, index) in screens[0].deviceImages" :key="index" :class="{ 'active': index === 0 }">
                                        <div class="image-container">
                                            <a :id="'carousel-selector-' + index" :data-slide-to="index" data-target="#custCarousel"> <span id="thumb_caption">{{ img.created_at_thumb }} </span> 
                                              <img :src="img.url" class="img-fluid" id="img-thumbnails"> 
                                            </a>
                                        </div> 
                                      </li>
                                  </ol>
                                </div>
                            </div>
                        </div>
                </CCardBody>
            </CCard>
        </CCol>
        <CCol>
            <CCard>
                <div class="title">{{ screens[1].title }}</div>
                <CCardBody>
                    <div id="custCarousel2" class="carousel slide carousel-fade" data-ride="carousel2" data-interval="3000" align="center" style="border:1px solid #eee; border-radius:1%; height:100%;">
                        <!-- 메인 슬라이드 이미지 slides -->
                        <div class="carousel-inner">
                            <div class="carousel-item" v-for="(img, index) in screens[1].deviceImages" :key="index" :class="{ 'active': index === 0 }">
                              <img :src="img.url" :alt="img.label"  style="max-height: 360px; cursor: pointer;" @click="openUploadDialog()">
                              <div class="row" id="main-img-caption">
                                  <div class="col-sm-12">
                                      <svg xmlns="http://www.w3.org/2000/svg" height="22" width="16" viewBox="0 0 512 500"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                                      <br>
                                      <span class="time"> {{img.created_at_thumb}} <br> {{ img.created_at_time }}</span>                    
                                  </div>
                              </div>
                            </div>
                        </div>
                        <!-- 화살표 indicator -->
                        <a class="carousel-control-prev" href="#custCarousel2" data-slide="prev">
                            <i class="fa fa-chevron-left" aria-hidden="true" style="color:#848484; font-size:xxx-large;font-weight:800"></i>
                        </a>
                        <a class="carousel-control-next" href="#custCarousel2" data-slide="next">
                            <i class="fa fa-chevron-right" aria-hidden="true" style="color:#848484;font-size:xxx-large;;font-weight:800"></i>
                        </a>
                        <!-- 썸네일 Thumnails -->
                        <div class="carousel-indicators-container">
                            <div class="carousel-indicators-wrapper">
                              <ol class="carousel-indicators list-inline">  <!--  hj :: for spacing add "me-4" at the end of the class -->
                                  <li class="list-inline-item" v-for="(img, index) in screens[1].deviceImages" :key="index" :class="{ 'active': index === 0 }">
                                    <div class="image-container">
                                        <a :id="'carousel-selector-' + index" :data-slide-to="index" data-target="#custCarousel2"> <span id="thumb_caption">{{ img.created_at_thumb }} </span> 
                                        <img :src="img.url" class="img-fluid" id="img-thumbnails"> 
                                        </a>
                                    </div> 
                                  </li>
                              </ol>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
    <CRow>
        <CCol>
            <!--제품정보 table - 제품번호/설치정보/업로드주기-->
            <CCard>
                <CCardBody>
                    <CRow>
                        <CCol col="12" md="12" xl="6">
                            <table class="table" v-bind="getDeviceData()">
                                <tbody>
                                    <tr style="margin-left:auto;margin-right:auto;">
                                        <th style="border-top:none;text-align: center;" colspan="2">
                                            <span style="color:#00A8FF;font-size:1.4em;">{{ site.name }} / {{ device.name }}</span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="border-top:none" scope="col">제품번호</th>
                                        <td style="border-top:none" scope="col">{{deviceGuid}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">설치일시</th>
                                        <td scope="col">{{installationDate}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">업로드 주기</th>
                                        <td scope="col">{{cronStringData}}</td>
                                    </tr>
                                    <tr>
                                      <th scope="col"> 센서정보관리</th>
                                      <td scope="col">
                                        <CButton color="warning" class="float-left mr-1" @click="openDialog('sensor')">
                                          <!-- <i class="fa fa-cog"></i> 수정 -->
                                          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#808080" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg> 수정
                                        </CButton>
                                        <CButton color="primary" class="float-left mr-1" @click="pictureDialog()">
                                          <!-- <i class="fa fa-camera"></i> 촬영  -->
                                          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg> 촬영
                                        </CButton>   
                                      </td>
                                    </tr>
                                    <tr v-if="is_admin == true">
                                      <th scope="col"> 펌웨어업로드</th>
                                      <td scope="col">
                                        <CButton style="background-color:#d9d9d9;font-weight: 800;" class="float-left mr-1" @click="openFirmwareUploadDialog()">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fcfcfc" d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg> 업로드
                                        </CButton>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CCol>
                        <CCol col="12" md="12" xl="6">
                          <CRow>
                            <CCol col="12" xl="12">
                              <CCard class="infoBox2">
                                <CRow class="align-items-center justify-content-center h-100">
                                  <CCol col="12" xl="3" class="text-center">
                                    <!-- <i :class="sensorIconClass" :style="{color:sensorIconColor}" style="font-size:xxx-large;"> </i>    -->
                                    <div v-html="dangerDetectionImg"></div>
                                  </CCol>
                                  <CCol col="12" xl="4" class="text-center">
                                    <div v-html="dangerDetectionData()" style="font-size:larger;margin-left:10%" ></div>
                                  </CCol>
                                  <CCol col="12" xl="3" class="text-center">
                                    <span style="font-size: 1em; color:#474747">
                                      {{ sensorUpdated }} <br>
                                      {{ sensorUpdatedTime }}
                                    </span>
                                  </CCol>
                                  <CCol col="12" xl="2" class="text-center">
                                    <CButton style="font-weight: 800; margin-bottom: 2%;" color="info" class="float-right mr-1" id="float-btn" @click="openDialog('incline')">기준값 설정</CButton>
                                    <CButton style="background-color:#eee;font-weight: 800;" class="float-right mr-1" id="float-btn" @click="openDialog('srds')">위험등급 설정</CButton>
                                  </CCol>
                                </CRow>
                              </CCard>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol col="12">
                              <CCard class="infoBox3">
                                <CRow class="align-items-center justify-content-center h-100">
                                  <CCol col="12" xl="3" class="text-center">
                                    <!-- <i :class="batteryIconClass" :style="{color: batteryIconColor}" style="font-size:xxx-large"> </i>                                     -->
                                    <div v-html="findBatteryImg"></div>
                                  </CCol>
                                  <CCol col="12" xl="6" class="text-center">
                                    <span style="font-size:larger"> 배터리 잔량  {{ getBatteryDataValue() }} </span>                  
                                  </CCol>
                                  <CCol col="12" xl="3" class="text-center">
                                    <CButton class="batteryBtn" style="font-weight: 800;" color="success" @click="openDialog('battery')">배터리 기준 설정</CButton>
                                  </CCol>
                                </CRow>
                              </CCard>
                            </CCol>
                          </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
  </template>
    <!-- 싱글카메라 UI -->
    <template v-else>
      <CCard>
        <CCardBody>
          <div class= "row" id="deviceContainer">
            <div class="col-sm-7 col-lg-7">
              <div id="custCarousel" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000" align="center" style="border:1px solid #eee; border-radius:1%; padding:2% 3% 0% 2%;height:100%;margin-right:1%;">
                <!-- 메인 슬라이드 이미지 slides -->
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(img, index) in deviceImages" :key="index" :class="{ 'active': index === 0 }">
                    <img :src="img.url" :alt="img.label"  style="max-height: 400px;cursor: pointer" @click="openUploadDialog()">
                    <div class="row" id="main-img-caption">
                        <div class="col-sm-12">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" width="16" viewBox="0 0 512 500"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                          <br>
                          <span> {{img.created_at}} <br> {{ img.created_at_time}}</span>                  
                        </div>
                    </div>
                  </div>
                </div>
                <!-- 화살표 indicator -->
                <a class="carousel-control-prev" href="#custCarousel" data-slide="prev">
                  <!-- <i class="fa fa-chevron-left" aria-hidden="true" style="color:#848484;font-size:xx-large;font-weight:800"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="32" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#848484" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                </a>
                <a class="carousel-control-next" href="#custCarousel" data-slide="next">
                  <!-- <i class="fa fa-chevron-right" aria-hidden="true" style="color:#848484;font-size:xx-large;;font-weight:800"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" height="48" width="32" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#848484" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
                <!-- 썸네일 Thumnails -->
                <div class="carousel-indicators-container">
                  <div class="carousel-indicators-wrapper">
                    <ol class="carousel-indicators list-inline">  <!--  hj :: for spacing add "me-4" at the end of the class -->
                      <li class="list-inline-item" v-for="(img, index) in deviceImages" :key="index" :class="{ 'active': index === 0 }">
                        <div class="image-container">
                          <a :id="'carousel-selector-' + index" :data-slide-to="index" data-target="#custCarousel"> <span id="thumb_caption">{{ img.created_at_thumb }} </span> 
                            <img :src="img.url" class="img-fluid" id="img-thumbnails"> 
                          </a>
                        </div> 
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-5 col-lg-5">
              <div class="row">
                <!--제품정보 table - 제품번호/설치정보/업로드주기-->
                <div class="col-sm-12 col-lg-12" style="border:1px solid #eee;border-radius:1%; right:2.5%;padding:3% 6% 1% 6%">   
                  <table class="table" v-bind="getDeviceData()">
                    <tbody>
                      <tr style="margin-left:auto;margin-right:auto;">
                        <th style="border-top:none;text-align: center;" colspan="2">
                          <span style="color:#00A8FF;font-size:1.4em;">{{ site.name }} / {{ device.name }}</span>
                        </th>
                      </tr>
                      <tr>
                        <th style="border-top:none" scope="col">제품번호</th>
                        <td style="border-top:none" scope="col">{{deviceGuid}}</td>
                      </tr>
                      <tr>
                        <th scope="col">설치일시</th>
                        <td scope="col">{{installationDate}}</td>
                      </tr>
                      <tr>
                        <th scope="col">설치주소</th>
                        <td scope="col">{{deviceAddr}}</td>
                      </tr>
                      <tr>
                        <th scope="col">업로드 주기</th>
                        <td scope="col">{{cronStringData}}</td>
                      </tr>
                      <tr>
                        <th scope="col"> 센서정보관리</th>
                        <td scope="col">
                          <CButton color="warning" class="float-left mr-1" @click="openDialog('sensor')">
                            <!-- <font-awesome-icon icon="fa-solid fa-gear" /> 수정 -->
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#808080" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg> 수정
                          </CButton>
                          <CButton color="primary" class="float-left mr-1" @click="pictureDialog()">
                            <!-- <i class="fa fa-camera"></i> 촬영  -->
                            <!-- <font-awesome-icon icon="fa-solid fa-camera" /> 촬영 -->
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg> 촬영
                          </CButton>   
                        </td>
                      </tr>
                      <tr v-if="is_admin == true">
                        <th scope="col"> 펌웨어업로드</th>
                        <td scope="col">
                          <CButton style="background-color:#d9d9d9;font-weight: 800;" class="float-left mr-1" @click="openFirmwareUploadDialog()">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fcfcfc" d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg> 업로드
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- 위험감지 -->
                <div class="col-sm-12" style="border:1px solid #eee;border-radius:5px; right:2.5%; padding: 2.5%; margin:1% 0% 1% 0%;text-align: center;"> 
                  <div class="row" style="padding: 0% 2% 0% 2%;align-items:center;">
                    <!-- box - 1 아이콘 -->        
                    <div class="col-sm-2" style="padding-right:3%;">
                      <!-- <i :class="sensorIconClass" :style="{color:sensorIconColor}" style="font-size:xxx-large;"></i> -->
                      <div v-html="dangerDetectionImg"></div>
                    </div>
                    <!-- box - 2 내용 -->
                    <div class="col-sm-5">
                      <div v-html="dangerDetectionData()" style="font-size:larger;margin-left:10%" ></div>
                    </div>
                    <div class="col-sm-2">
                      <span style="font-size: 0.9em;color:#474747">
                      {{ sensorUpdated }} <br>
                      {{ sensorUpdatedTime }}
                    </span>
                    </div>
                    <!-- box - 3 버튼 -->
                    <div class="col-sm-3">
                      <CButton style="margin-top:1.8%;font-weight: 800;" color="info" class="float-right mr-1" id="float-btn" v-show="service_type == 'srds'" @click="openDialog('incline')">기준값 설정</CButton>
                      <CButton style="background-color:#eee;margin-top:1.8%;font-weight: 800;" class="float-right mr-1" id="float-btn" v-show="service_type == 'srds'" @click="openDialog('srds')">위험등급 설정</CButton>
                    </div>
                  </div>
                </div>  
                <!-- 배터리 -->  
                <div class="col-sm-12" style="border:1px solid #eee;border-radius:5px; right:2.5%; padding: 2.5%; margin:1% 0% 1% 0%;text-align: center;"> 
                  <div class="row" style="padding: 0% 2% 0% 2%; align-items:center;">
                    <!-- box - 1 아이콘 -->        
                    <div class="col-sm-2">
                      <!-- <i :class="batteryIconClass" :style="{ color:batteryIconColor }" style="font-size:xxx-large"> </i> -->
                      <div v-html="findBatteryImg"></div>
                    </div>
                    <!-- box - 2 내용 -->
                    <div class="col-sm-7">
                      <span style="font-size:larger"> 배터리 잔량  {{ getBatteryDataValue() }} </span>             
                    </div>
                    <!-- box - 3 버튼 -->
                    <div class="col-sm-3">
                      <CButton color="success" class="float-right mr-1" id="float-btn-battery" @click="openDialog('battery')" >배터리 기준 설정</CButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </CCardBody>
      </CCard> 
    </template>
    <Confirm
        ref="confirmDialog"
        title="확인"
        @hide="hideModal"
        color="warning"
      />
      <DeviceInfo
        ref="deviceInfo"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :device.sync="device"
        @hide="hideModal"
        @update="onUpdated"
      />
      <SRDSThreshold
        ref="srdsthresholdDialog"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :device.sync="device"
        @update="onUpdated"
      />
      <Incline 
        ref="srdsInclineDialog"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        @update="onUpdated"
      />
      <Threshold
        ref="thresholdDialog"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :device.sync="device"
        @update="onUpdated"
      />
      <Uploader
        id="imageUploader"
        ref="uploaderDialog"
        :device_guid="device_guid"
        :storage.sync="storage"
        @hide="hideModal"
      />
      <FirmwareUpload
        id="firmwareUploader"
        ref="firmwareuploaderDialog"
        :device_guid="device_guid"
        :storage.sync="storage"
        @hide="hideModal"
      />

  </div>
</template>
<script>
import loopback from '@/services/loopback';
import { IMAGES } from "@/constants/constant"

import moment from 'moment';
import utils from '@/services/utils';
import Device from '@/views/device/Device'
import DeviceInfo from '@/views/srds/device/DeviceInfo'
import Incline from '@/views/srds/device/Incline'
// import DualCameraDevice from '@/views/srds/device/DualCameraDevice'

// 펌웨어업로드
import FirmwareUpload from '@/views/srds/device/FirmwareUpload'

export default {
  name: 'DeviceExt',
  extends: Device,
  components: {
    DeviceInfo,
    Incline,
    FirmwareUpload
    // DualCameraDevice
  },
  data(){
    return{
      dangerDetectionImg: '',
      findBatteryImg: '',
      deviceGuid: '',
      deviceAddr: '',
      installationDate : '', 
      cronStringData : '',
      deviceStatus : '', 
      batteryIconColor : '',
      batteryIconClass : '', 
      sensorIconColor: '',
      sensorIconClass:'',
      sensorUpdated : '',
      sensorUpdatedTime : '',
      screens: [
        {
            title: '카메라 #1',
            direction: 'left',
            deviceImages: []
        },
        {
            title: '카메라 #2',
            direction: 'right',
            deviceImages: []
        }
      ]
      
    }
  },
  mounted(){
    this.getBatteryDataValue();
    this.getDualDeviceImages();
  },
  methods: {
    getDeviceData(){
      if(_.isUndefined(this.device.sensor))
        return;
      this.deviceGuid = _.get(this.device, "deviceid") + this.device.guid;
      this.deviceAddr = _.get(this.device, "address", "");
      this.installationDate= moment(_.get(this.device, "install_date", "")).format("YYYY-MM-DD HH:mm:ss");
      this.cronStringData = this.getCronStringValue(); 
      this.deviceStatus = _.get(this.device, "status");
      this.sensorUpdated = moment(this.device.sensor.created_at).format('YYYY.MM.DD')
      this.sensorUpdatedTime = moment(this.device.sensor.created_at).format('HH시 mm분')
    }, 
    getCronStringValue(){
      var cronStringValue; 
      var cron_string = _.get(this.device, 'upload_cron');
      if (cron_string && cron_string !== "") {
        var cronArray = cron_string.replace(/\s+/g, " ").split(" ");
        if (cronArray.length == 6) {
          cron_string = cronArray.slice(1).join(' ');
        }
        cronStringValue = this.cronStringFormatter(cron_string);
      } else {
        cronStringValue = _.get(this.device, "upload_period")
      }
    
      return cronStringValue; 
    },
    openUploadDialog() {
      this.$refs.uploaderDialog.show();
    }, 
    openFirmwareUploadDialog() {
      this.$refs.firmwareuploaderDialog.show();
    },
    pictureDialog() {
      var message = this.device.name + " 의 사진촬영을 진행하시겠습니까?"
      this.$refs.confirmDialog.show(message, '사진 촬영', undefined, 'pic');      
    }, 
    hideModal(event) {
      console.log("hideModal:: event : ", event)
      if (event.name === 'Uploader' && event.which === 'update') {
        if (event.data.need_refresh)
          this.getImages();
        return
      } 
      else if(event.data === 'confirm' && event.which === 'pic'){
        this.takePicture();
        return
      }
      else if (event.data !== 'confirm' || event.which !== 'reset') {
        return; // cancel
      }
    },
    takePicture() {
      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'shot',
          data: "takepic.jpg",
          opt: {
            "-w": 640,
            "-h": 480,
            "-q": 5,
            "-o": "takepic.jpg",
            "-ev": -10,
            "-ex": "backlight",
            "-mm": "backlit",
            "-awb": "greyworld"
          }
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })
    },        
    findBatteryImgs() {
      var result = "";
      var imgsrc;
      var thresholds = utils.getThresholds();

      try {
        var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
        var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
        var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
        var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      } catch(err) {
        return;
      }

      if (_.isUndefined(this.sensor)){
          console.log("isUndefined");
          return;
      }
      this.sensor.data.forEach(function(e){
        if(e.type === 1)
          result = e.value;
      })
      

      if (result >= level0){ 
        console.log("level0", result, level3);
        // imgsrc = `<i class="fa fa-battery-full fa-lg" style="color:green;"></i>`
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#008000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm368 96H96V320H448V192z"/></svg>'
      }
      else if (result >= level1 ) { 
        console.log("level1", result, level3);
        // imgsrc = `<i class="fa fa-battery-three-quarters fa-lg" style="color:orange;"></i>`
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm272 96H96V320H352V192z"/></svg>'
      }
      else if (result >= level2 ) {
        console.log("level2", result, level3);                
        // imgsrc = `<i class="fa fa-battery-half fa-lg" style="color:orange;"></i>`
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffa500" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm208 96H96V320H288V192z"/></svg>'

      }
      else if (result >= level3 ){
        console.log("level3", result, level3);
        imgsrc = `<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>`        
      }
      else {
        //console.log("교체필요");              
        return `<div class="containerBox"><div><svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg></div></div>`
      }
      return `<div class="containerBox"><div>${imgsrc}</div></div>`
    },
    getBatteryDataValue() {
      if(_.isUndefined(this.device.sensor))
        return;
      // this.findBatteryImgs()
      var percentage = 0; 
      var batteryData; 
      
      if(this.sensor.data && this.sensor.data.length > 0) {
        batteryData =  this.sensor.data.find(
          (item) => item.type === 1 && item.id === 1
        ).value; 
      }

      if(batteryData > 4) { 
        // 배터리 100% - 좋음
        percentage = 100; 
        this.batteryIconColor = "#198754"; 
        this.batteryIconClass="fa fa-battery-full"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#008000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm368 96H96V320H448V192z"/></svg>'
      }  /* "fa fa-battery-full"  */
      else if(batteryData > 3.7 && batteryData <= 4) { 
        // 배터리 80% - 좋음
        percentage =  80; 
        this.batteryIconColor = "#198754"; 
        this.batteryIconClass="fa fa-battery-three-quarters"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#198754" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm272 96H96V320H352V192z"/></svg>'
      }
      else if(batteryData > 3.6 && batteryData <= 3.7) { 
        // 배터리 50% - 보통
        percentage =  50; 
        this.batteryIconColor = "#ffc107"; 
        this.batteryIconClass="fa fa-battery-half"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#000000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm208 96H96V320H288V192z"/></svg>'

      }
      else if(batteryData >3.5 && batteryData <= 3.6) { 
        // 배터리 30% - 주의
        percentage =  30; 
        this.batteryIconColor = "#ffc107"; 
        this.batteryIconClass="fa fa-battery-half"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffc107" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'

      }
      else if(batteryData >3.4 && batteryData <= 3.5) { 
        // 배터리 10% - 경고
        percentage =  10; 
        this.batteryIconColor = "#fd7e14"; 
        this.batteryIconClass="fa fa-battery-quarter"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
      }
      else if(batteryData <= 3.4) { 
        // 배터리 5% - 경고
        percentage =  5; 
        this.batteryIconColor = "red"; 
        this.batteryIconClass="fa fa-battery-empty";
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
      }
      else { 
        // 배터리 교체필요
        percentage = 0; 
        this.batteryIconColor = "red"; 
        this.batteryIconClass="fa fa-battery-empty"; 
        this.findBatteryImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
      }

      var script  = percentage + " % (" + batteryData + " V)" 
      return script;
    },
    dangerDetectionData() {
      if(_.isUndefined(this.device.sensor))
        return;      
      if(_.isUndefined(this.device.standard))
        return;

      let thresholds = !_.isUndefined(_.find(this.device.threshold, {sensor_type : 8})) ? this.device.threshold : utils.getThresholds();

      const level0 = _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0  -- 최소값 정상
      const level2 = _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3  -- 최소값 주의
      const level3 = _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5  -- 최소값 위험
  
      let standard = _.get(this.device, "standard");

      /* 현재값 */
      let xData = _.get(_.find(this.device.sensor.data, { type: 8, id: 1 }), 'value', null);
      let yData = _.get(_.find(this.device.sensor.data, { type: 8, id: 2 }), 'value', null);
      let zData = _.get(_.find(this.device.sensor.data, { type: 8, id: 3 }), 'value', null);
      
      /* 차이값 */
      let xDiff = standard.x - xData;
      let yDiff = standard.y - yData;
      let zDiff = standard.z - zData

      // 절대값
      let xDiffAbs = Math.abs(xDiff);
      let yDiffAbs = Math.abs(yDiff);
      let zDiffAbs = Math.abs(zDiff);

      /* span color */
      let xColor = "#198754";
      let yColor = "#198754";

      /* Diff Result */
      var xDiffResult = (xDiff >= 0) ? "우측" : "좌측";
      var yDiffResult = (yDiff >= 0) ? "전방" : "후방"

      if(xDiffAbs > level3 || yDiffAbs > level3) {
        if(xDiffAbs > level3){
          xColor = "red";
        }if(yDiffAbs > level3){
          yColor = "red";
        }
        this.sensorIconClass ="fa fa-warning";
        this.sensorIconColor = "red"
        this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>'

      } else if(xDiffAbs > level2 || yDiffAbs > level2) {
          if(xDiffAbs > level2){
            xColor = "#fd7e14";
          }if(yDiffAbs > level2){
            yColor = "#fd7e14"
          }
        this.sensorIconClass = "fa fa-warning";
        this.sensorIconColor = "#fd7e14"
        this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffa500" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>'
      } else{
        this.sensorIconClass = "fa fa-check-circle";
        this.sensorIconColor = "#198754"
        this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#198754" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>'
      } 
    
      let result = xDiffResult + "<span style='color: " + xColor + "'> ( " + xDiff.toFixed(2) + "º )</span>도 기울어짐 <br>" +  yDiffResult + "<span style='color: " + yColor + "'> ( " + yDiff.toFixed(2) + "º )</span>도 기울어짐 ";
      
      return result; 

    },  
    getDeviceImages() {
      var self = this;
      this.deviceImages = [];
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
        var filter = {
          where: {
            and: [{
                device_guid: self.device_guid
              },
            ]
          },
          order: 'created_at desc',
          limit: 10,
        }

        self.$store.dispatch('rest/find', {model:'photos',filter:filter})
          .then(function(response) {
            var pushImage = function(info) {
              self.deviceImages.push({
                url: download_url + '/' + info.file_name,
                // created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
                created_at: moment(info.created_at).format('YYYY년 MM월 DD일'),
                created_at_thumb: moment(info.created_at).format('YYYY-MM-DD'),
                created_at_time: moment(info.created_at).format('HH시 mm분 ss초')
              })
            }
            for (var i = 0; i < response.length; i++) {
              var info = response[i]
              if (_.isUndefined(info.storage)) continue;
              pushImage(info);
            }
          if (self.deviceImages.length == 0) {
            var network_type = self.device.network_type;
            download_url = 'img/tech9_dev.png'; 
            switch (network_type.toLowerCase()) {
              case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
              case 'lora': download_url = 'img/tech9_lora.jpg'; break;
            }
            self.deviceImages.push({
              url: download_url
            })
          }
        })
    },
    getDualDeviceImages() {
        var self = this;
        this.screens.forEach(view => {
          view.deviceImages = []
        })

        let filter = {
          where: {
            and: [{
              device_guid: this.device_guid
            }]
          },
          order: 'created_at desc',
          limit: 20,
        }

        this.$store.dispatch('rest/find', { model: 'photos', filter: filter })
          .then(res => {
            let pushImage = (info) => {
              let path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, self.device_guid, info.file_name)
              let index = info.flow_type === 'out' ? 0 : 1
              self.screens[index].deviceImages.push({
                url: path,
                created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초'),
                created_at_thumb: moment(info.created_at).format('YYYY-MM-DD'),
                created_at_time: moment(info.created_at).format('HH시 mm분 ss초')
            })
          }
          for(let i = 0; i < res.length; i++) {
            var info = res[i]
            if (_.isUndefined(info.storage)) continue;
            pushImage(info)
          }
        })
      },
  }
}
</script>

<style lang="scss" scoped>
#deviceContainer{ 
  font-family: 'Noto Sans KR', sans-serif;
}
.title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
}
.carousel-inner img {
    width: 100%;
    /* height: 340px;
    max-width: 450px; */
    
}
#custCarousel .carousel-indicators {
    position: static;
    margin-top:0.5px;
    margin-left:1px;
    
}

#custCarousel .carousel-indicators > li {
  width:120px;
  
}

#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.75;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
  
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.95;
}

#custCarousel .carousel-control-prev {
  width: 54px;
}

#custCarousel .carousel-control-prev .fa-chevron-left {
  position: absolute;
  top: 30%;
}

#custCarousel .carousel-control-next {
  width: 54px;
}

#custCarousel .carousel-control-next .fa-chevron-right {
  position: absolute;
  top: 30%;
}
// custCarousel
#custCarousel2 .carousel-indicators {
    position: static;
    margin-top:0.5px;
    margin-left:1px;
    
}

#custCarousel2 .carousel-indicators > li {
  width:120px;
  
}

#custCarousel2 .carousel-indicators li img {
    display: block;
    opacity: 0.75;
}

#custCarousel2 .carousel-indicators li.active img {
  opacity: 1;
  
}

#custCarousel2 .carousel-indicators li:hover img {
  opacity: 0.95;
}

#custCarousel2 .carousel-control-prev {
  width: 54px;
}

#custCarousel2 .carousel-control-prev .fa-chevron-left {
  position: absolute;
  top: 30%;
}

#custCarousel2 .carousel-control-next {
  width: 54px;
}

#custCarousel2 .carousel-control-next .fa-chevron-right {
  position: absolute;
  top: 30%;
}

.carousel-item img{
  width:86%;
}
.carousel-indicators-container {
  height: 130px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 86%;
}
.carousel-indicators-container::-webkit-scrollbar{
  height: 10px;

}
.carousel-indicators-container::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: #00000064; /* 스크롤바의 색상 */
  border-radius: 10px;


}

.carousel-indicators-container::-webkit-scrollbar-track {
  background: #eee  /*스크롤바 뒷 배경 색상*/
}


.carousel-indicators-wrapper {
  display: flex;

}

#img-thumbnails{
  height: 86px;
}


#carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #fff;
    text-align: center;
    background-color: cornflowerblue;
    border-radius: 2%;
}

#main-img-caption {
  position: absolute;
  bottom: 0; /* 이미지 상단 시 top : 0 */
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2%;
  width: 80%;
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
}

#thumbnail-caption{
  bottom: 10%;
  position: sticky;

}

#thumb-content{
  font-size: 15px;
}

.image-container {
  position: relative;
}

.caption-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
  color: #fff; 
  padding: 10px;
  box-sizing: border-box;
}

.caption {
  margin: 0;
}


#thumb_caption{
  padding: 3%;
  border-radius: 10%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  position: relative;
  left: 415%;
  top: 30px;
  z-index: 3;
}

#thumb_caption:hover {
  opacity: 0.95;
}

#float-btn{
  width: 126px;
}

#float-btn-battery {
  width: 134px;
  font-size: 14px;
}

.infoBox2 {
  margin-top: 2%;
  height: 110px;
}

.infoBox3 {
  height: 90px;
}

@media (max-width: 768px) {
  #custCarousel .carousel-control-prev {
    width: 40px;
  }

  #custCarousel .carousel-control-prev .fa-chevron-left {
    position: absolute;
    top: 22%;
  }

  #custCarousel .carousel-control-next {
    width: 40px;
  }

  #custCarousel .carousel-control-next .fa-chevron-right {
    position: absolute;
    top: 22%;
  }

  .time {
    font-size: 12px;
  }

  #main-img-caption {
    position: absolute;
    bottom: 0; /* 이미지 상단 시 top : 0 */
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 4px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2%;
    width: 70%;
    max-width: 500px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  #custCarousel2 .carousel-control-prev {
    width: 40px;
  }

  #custCarousel2 .carousel-control-prev .fa-chevron-left {
    position: absolute;
    top: 22%;
  }

  #custCarousel2 .carousel-control-next {
    width: 40px;
  }

  #custCarousel2 .carousel-control-next .fa-chevron-right {
    position: absolute;
    top: 22%;
  }

  .time {
    font-size: 12px;
  }

  .infoBox2 {
    height: 200px;
  }

  .infoBox3 {
    height: 120px;
  }

  #main-img-caption {
    position: absolute;
    bottom: 0; /* 이미지 상단 시 top : 0 */
    left: 50%;
    transform: translateX(-50%);
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2%;
    width: 70%;
    max-width: 500px;
    box-sizing: border-box;
  }

}

</style>