<template>
  <div ref="aap">
    <CRow>
      <CCol col="12">
        <p>
          <strong><center>{{ title }}</center></strong>
        </p>
        <vue-gauge
          :refid="'_' + Math.random().toString(36).substr(2, 9)"
          :options="myOptions"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import VueGauge from 'vue-gauge';

export default {
  components: { VueGauge },
  props: {
    title: String,
    value: Number,
    thresholds: Array,
  },
  data() {
    return {
      angleData: 0,
      myOptions: {
        chartWidth: 360,
        needleValue: Math.abs(this.value) / 0.85,
        needleColor: this.changeNeedleColor(),
        arcDelimiters: [`${this.updateAngleRangeMin()}` / 0.85, `${this.updateAngleRangeMax()}` / 0.85],
        arcColors: ["#43A047", "#F17C08", "#E6203B"],
        arcLabels: [`${this.updateAngleRangeMin()}°`, `${this.updateAngleRangeMax()}°`],
        rangeLabel: ["0°", "90° 이상"],
        centralLabel: `${this.setAngleData()}`,
        rangeLabelFontSize: 13,
        arcLabelFontSize: 12,
      },
    };
  },
  methods: {
    // 니들컬러
    changeNeedleColor() {
      if(Math.abs(this.value) >= this.updateAngleRangeMax()) {
        return "#E6203B"
      } else if(Math.abs(this.value) < this.updateAngleRangeMax() && Math.abs(this.value) >= this.updateAngleRangeMin()) {
        return "#F17C08"
      } else {
        return '#43A047'
      }
    },
    setAngleData() {
      let angle = this.value
      let tmp = Math.abs(angle)
      return tmp.toFixed(2)
    },
    updateAngleRangeMin() {
      let range = this.thresholds
      let angle_min = range[1].min
      // let angle_max = range[2].min
      return angle_min
    },
    updateAngleRangeMax() {
      let range = this.thresholds
      // let angle_min = range[1].min
      let angle_max = range[2].min
      return angle_max
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
