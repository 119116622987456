<template>
  <div>
      <div v-if="isStandard" :id="'eulerAngularStandard_'+device_guid" class="EulerAngular" style="text-align: center;">
      </div>

      <div v-else :id="'eulerAngular_'+device_guid" class="EulerAngular" style="text-align: center;">
      </div>    
  </div>
</template>

<style>
.EulerAngular {
  width: 100%;
  height: 400px;
}
</style>

<script>
import * as THREE from 'three'
import moment from 'moment';

export default {
  name: 'EularAngular',
  components: {
  },
  props: {
    content: {
      type: Array
    },
    timeout: {
      type: Number,
      default: 100
    },
    device_guid: {
      type: String,
    },
    isStandard: {
      type: Boolean,
      default: false
    },
  },
  mounted: function() {
    // console.log('EularAngular - {0} ({1}:{2})'.format(this.content.title, this.content.type, this.content.icon))
    this.generate(this.content)
  },
  beforeDestroy() {
    clearTimeout(this.animation_timer)
  },
  data () {
    return {
      title: '9Axis - 기울기',
      icon: ['fa', 'umbrella'],
      date: moment().format('YYYY/MM/DD-HH:mm:ss'),
      roll: 0,
      pitch: 0,
      yaw: 0,
      pos: 0,
      length: -1,
      animation_timer: null,
      simulator_mode: false,
      eulerAngular_div_id: ''
    }
  },
  methods: {
    generate(contents) {
      var content = _.first(contents);
      var self = this;
      this.pos = 0;
      this.length = content.sensors[0].length;
      self.eulerAngular_div_id = self.isStandard ? 'eulerAngularStandard_' : 'eulerAngular_';
      var div = document.getElementById(self.eulerAngular_div_id + self.device_guid);
      var width = div.clientWidth;
      var height = 350;//div.clientHeight - 5;
      var simulator_mode = this.simulator_mode; // TEST ONLY
      var emitListener = [];

      var scene = new THREE.Scene(),
        camera = new THREE.PerspectiveCamera(65, width / height, 0.1, 1000),
        renderer = new THREE.WebGLRenderer({
          antialias: true
        }),
        group;

      function handleWindowResize() {
        // update height and width of the renderer and the camera
        self.eulerAngular_div_id = self.isStandard ? 'eulerAngularStandard_' : 'eulerAngular_';
        var div = document.getElementById(self.eulerAngular_div_id + self.device_guid);
        if (_.isUndefined(div))
          return;
        var width = div.clientWidth;
        var height = 200;//div.clientHeight - 5;

        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      }

      function initialize() {
        renderer.setClearColor(0xFFFFFF);
        renderer.setSize(width, height);
        renderer.setPixelRatio(window.devicePixelRatio);
        div.appendChild(renderer.domElement);

        var geometry = new THREE.BoxGeometry(1, 1, 1),
          material = new THREE.MeshLambertMaterial({
            color: 0x00ff00
          });


        // DIRECTIONAL SHINES FROM A DIRECTION AND ACTS LIKE THE SUN.
        var dirLight = new THREE.DirectionalLight(0xffffff, 1);
        dirLight.position.set(100, 100, 50);
        scene.add(dirLight);
        // AMBIENT GETS APPLIED TO ALL THE OBJECTS IN THE SCENE GLOBALLY.
        var ambientLight = new THREE.AmbientLight(0x404040); // soft white light
        scene.add(ambientLight);
        // POINT AFFECTS OBJECTS USING MESHLAMBERTMATERIAL OR MESHPHONGMATERIAL.
        var pointLight = new THREE.PointLight(0xffffff);
        pointLight.position.set(50, 50, 50);
        scene.add(pointLight);

        group = new THREE.Object3D();

        // THE THORUS BASE.
        var torus = new THREE.TorusGeometry (9, 1, 16, 100);
        group.add(new THREE.Mesh(torus,
          new THREE.MeshPhongMaterial({
            transparent: true,
            opacity: 0.7,
            color: 0x666666,
            wireframe: false
          })));

        // THE RED VERTICAL YAW LINE.
        var boxYaw = new THREE.BoxGeometry(1, 1, 16);
        group.add(new THREE.Mesh(boxYaw,
          new THREE.MeshPhongMaterial({
            color: 0xff9933
          })));

        // THE GREEN HORIZONTAL PITCH LINE.
        var boxPitch = new THREE.BoxGeometry(1, 16, 1);
        group.add(new THREE.Mesh(boxPitch,
          new THREE.MeshPhongMaterial({
            transparent: true,
            opacity: 0.9,
            color: 0x33ff33
          })));

        // THE BLUE HORIZONTAL ROLL LINE.
        var boxRoll = new THREE.BoxGeometry(16, 1, 1);
        group.add(new THREE.Mesh(boxRoll,
          new THREE.MeshPhongMaterial({
            transparent: true,
            opacity: 0.9,
            color: 0x3333ff
          })));
        scene.add(group);

        // makePerson(9, 150, 100, 'x축', 0x3333ff);
        // makePerson(13, 150, 100, 'y축', 0x33ff33);
        // makePerson(17, 150, 100, 'z축', 0xff9933);    
        camera.position.z = 25
        camera.aspect = 1;
        camera.updateProjectionMatrix();

        window.addEventListener('resize', handleWindowResize, false);
      }

      function animate() {
        requestAnimationFrame(animate);

        if (simulator_mode) { // TEST ONLY
          self.roll += 0.11;
          self.yaw += 0.01;
          self.pitch += 0.11;
        }

        group.rotation.x = 90 + (self.pitch / 45.0);
        group.rotation.y = -(self.roll / 45.0);
        //group.rotation.z = -(self.yaw / 45.0);
        renderer.render(scene, camera)
      }

      function traverse(sensors) {
        self.date = moment(sensors[0][self.pos][0]).format('YYYY/MM/DD-HH:mm:ss');       
        self.roll  = sensors[0][self.pos][1];
        self.pitch = sensors[1][self.pos][1];
        //self.yaw   = sensors[2][self.pos][1];

        var div_date = document.getElementById('sensor_date');
        if (!div_date) return;
        div_date.innerHTML ='<h6 style="color:SkyBlue;margin: 0px 5px; font-size: 15px;"> 측정일자: '+ self.date  +'</h6>' +
            '<div class="row" style="margin-right: auto; justify-content: flex-end;">' +
              '<h6 style="font-weight: bold; font-size: 12px; color:#3333ff;margin: 0px 5px;">x축: (' + self.roll + ')</h6>'+
              '<h6 style="font-weight: bold; font-size: 12px; color:green;margin: 0px 5px;">y축: (' + self.pitch + ')</h6>' +
              //'<h6 style="font-weight: bold; font-size: 12px; color:#ff9933;margin: 0px 5px;">z축: (' + self.yaw + ')</h6>' +
            '</div>';

        //console.log(self.isStandard);
        if(self.isStandard){
           self.roll  = content.standard.x;//sensors[0][self.pos][1];
           self.pitch = content.standard.y;//sensors[1][self.pos][1];
           //self.yaw   = content.standard.z;//sensors[2][self.pos][1];
        }
        self.pos = self.pos == self.length - 1 ? 0: self.pos + 1;
        self.animation_timer = setTimeout(() => {
          traverse(sensors);
        }, self.timeout)
      }

      initialize();
      animate();
      self.animation_timer = setTimeout(function() {
        traverse(content.sensors);
      }, self.timeout)
      handleWindowResize();
    }
  }, // method
}
</script>
