<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="CameraEditor Dialog"
    :color="color"
  >
    <!-- size="lg" -->

    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>

    <!-- <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    /> -->

    <div class="modal-body">
      <div class="row" style="width:100%">
        <vue-cropper
          ref="cropper"
          :aspect-ratio="16 / 9"
          :src="editFile.name"
          :cropBoxResizable="false"
          :zoomOnWheel="false"
          preview=".preview"
        />
      </div>

      <div class="search-margin"></div>

      <div class="row">
        <div class='col-sm-12 col-lg-12'>
          <CButton color="primary" @click="zoom(0.1)" title="zoom(0.1)"><FontAwesomeIcon :icon="['fa', 'search-plus']" aria-hidden="true"/></CButton>
          <CButton class="m-1" color="primary" @click="zoom(-0.1)" title="zoom(-0.1)"><FontAwesomeIcon :icon="['fa', 'search-minus']" aria-hidden="true"/></CButton>
          <CButton color="primary" @click="reset()" title="reset()"><FontAwesomeIcon :icon="['fa', 'sync-alt']" aria-hidden="true"/></CButton>
          <CButton class="float-right" @click="cancel()" color="light">취소</CButton>
          <CButton class="float-right mr-1" @click="save()" color="info">저장</CButton>
        </div>
      </div>
    </div>

    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>

  </KModal>

</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
  name: 'CameraEditor',
  components: {
    VueCropper
  },
  props: {
    color: {
      type: String,
      default: 'info'
    },
  },
  data() {
    return {
      showModal: false,
      title: '최근 사진 카메라 위치 편집',
      which: 'confirm',
      button: '확인',
      editFile: {
        show: false,
        name: '',
      },
      cropImg: '',
      data: null,    
      zoom_value: 0  
    }
  },
  watch: {
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    cropImageFile() {
      let data = {
        name: this.editFile.name,
        error: '',
      }

      let binStr = atob(this.$refs.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      data.file = new File([arr], data.name, { type: this.editFile.type })
      data.size = data.file.size
      return data
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
      console.log("getCropBoxData: ", this.data)
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
      //console.log("getData: ", this.data)
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.zoom_value = 0;
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          // this.editFile.name = file.name
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    setImageFile(file) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      if(percent == 0.1)
        this.zoom_value = this.zoom_value + 1;
      else if(percent == -0.1)
        this.zoom_value = this.zoom_value - 1;

      if(this.zoom_value >= -10 && this.zoom_value < 10)
        this.$refs.cropper.relativeZoom(percent);
      else if(this.zoom_value >= 10)
        this.zoom_value = 10;
      else if(this.zoom_value < -10)
        this.zoom_value = -10;
    },    
    save() {
      //this.getData();
      //console.log(this.$refs.cropper.getImageData());
      var init_cropper_data = JSON.parse(this.data);
      var reload_cropper_data = this.$refs.cropper.getCropBoxData();

      var top = Math.round(init_cropper_data.top - reload_cropper_data.top);
      var left = Math.round(init_cropper_data.left - reload_cropper_data.left);

      var data = {
        'top': top==null?0:top,
        'left': left==null?0:left,
        'zoom': this.zoom_value
      }
      this.getCropBoxData();
      this.execute(data);
      this.showModal = false;      
    },
    execute(ctrl_data) {
      var self = this;
      //var enabled = _.filter(this.modified, {enable:true});
      var data = {

        command: 'exec',
        device_guid: this.editFile.guid,
        params: [{
          cmd: 'exec',
          type: 'pic',
          data: {
            type: 'pic',
            id: 1,
            zoom: ctrl_data.zoom,
            top: ctrl_data.top,
            left: ctrl_data.left,
          },
          site_guid: this.editFile.site_guid
        }]
      }
      console.log(data);
      // enabled.forEach(e => {
      //   data.params[0].data[e.field] = (self.type === 'submerged' && this.which === 'man') ? 1 : e.time;
      // })
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          this.showModal = false;
        })
        .catch(err => {
          console.log('ZoomCtrl Dialog execute error: ', err.toString())
          //this.$refs.notifyDialog.show(err.toString())
          setTimeout(function() {
            self.showModal = false;
          }, 500)
        })
    },
    cancel() {
      this.editFile.show = false      
      this.showModal = false;
    },
    show(file) {
      this.zoom_value = 0;
      this.editFile = JSON.parse(JSON.stringify(file, null, 4));
      this.$refs.cropper.replace(this.editFile.name);
//      console.log(this.editFile);
      var self = this;
      this.showModal = true;
      setTimeout(function() {
        self.getCropBoxData();
      }, 100);
    }
  }
}
</script>

<style>
.search-margin {
  margin: 10px 0 10px;
}

.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

</style>
