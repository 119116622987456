<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">가중치 설정</h6>
    </template>

    <div class='modal-body'>
      <div style="text-align: center;">
        <h5 style="font-weight: bold;">현재 가중치 분석 결과</h5>
        <h2 v-html="weight"></h2>
      </div>
      <div style="margin-top: 50px; margin-bottom: 15px;">
        <vue-slider 
              ref="rangeslider"
              v-model="value" 
              tooltip="none" 
              :process="process" 
              :min-range="0.1" 
              :min="0"
              :max="1"
              :interval="0.1"
              style="height: 15px;"
              @change="setThreshold"
          >
          <template v-slot:process="{ start, end, style, index }">
            <div class="vue-slider-process" :style="style">
              <div :class="[
                'merge-tooltip',
                'vue-slider-dot-tooltip-inner',
                'vue-slider-dot-tooltip-inner-top',
              ]" :style="labelbackground[index]">
                  {{ label[index] }} <br>
                  {{ value[index] }} ~ {{ value[index + 1] }}
              </div>
            </div>
          </template>
        </vue-slider>
      </div>
            
      <form name='thresholdForm' style="text-align: center; border-top: 5px solid; border-color: #d8dbe0;">
        <div class='form-group'>
          <div class='row d-sm-down-none' style="margin-top: 15px; margin-bottom: -15px;">
            <div class='col-sm-3 col-lg-3'>
              <label>구분</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>정상</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>주의</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>위험</label>
            </div>
          </div>
        </div>

        <div class='form-group' style="margin-bottom: -20px;">
            <div class="row"> 
              <div class='col-sm-3 col-lg-3'>
                <label class='labels'>최소값 <strong class='text-danger'>*</strong></label>
                <label class='labels'>최대값 <strong class='text-danger'>*</strong></label>
                <label class='labels'>푸쉬알람</label>
              </div>               
              <div class='col-sm-3 col-lg-3' v-for="(threshold, index) in thresholds">
                <!--<input type='text' class='form-control text-center' :style="{'background-color': threshold.color, 'font-weight': 'bold'}" v-model='threshold.name' readonly> -->
                <input type='number' style="font-size:12px; margin-bottom: 5px;" class='form-control' min=0 max=1 v-model='threshold._min' @input='setRange(index)' @change='changeRange(index, threshold, true)' :step=0.1>
                <input type='number' style="font-size:12px; margin-bottom: 5px;" class='form-control' min=0 max=1  v-model='threshold._max' @input='setRange(index)' @change='changeRange(index, threshold, false)' :step=0.1>
                <CSwitch class="mr-1" color="info" :checked.sync="threshold.is_push"/>
              </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';
import Threshold from '@/views/device/Threshold'
//import RangeSlider from '@/views/swfm/device/RangeSlider'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'SWFMThreshold',
  extends: Threshold,
  components:{
    VueSlider,
  },
  data() {
    return {
      label:['정상', '주의', '위험'],
      labelbackground:['background-color: #2eb85c;', 'background-color: #f9b115;', 'background-color: red;'],
      value: [0, 0.5, 0.8, 1],
      weight: 0.0,
      process: val => [
          [val[0], val[1], { backgroundColor: '#2eb85c' }],
          [val[1], val[2], { backgroundColor: '#f9b115' }],
          [val[2], val[3], { backgroundColor: 'red' }],
      ]
    }
  },
  methods: {
    setRange(index){
      this.value[index] = this.thresholds[index]._min; 
      this.value[index+1] = this.thresholds[index]._max; 
      this.$refs.rangeslider.setValue(this.value);
    },
    setThreshold(){
      this.value = this.$refs.rangeslider.getValue();
      for(var i = 0; i < 3; i++){
        this.thresholds[i]._min = this.value[i]; 
        this.thresholds[i]._max = this.value[i+1];
      }
    },
    changeRange(index, threshold, min) {
      var next_index = -1;
      if ((threshold.sensor_type == 32)&&threshold.sensor_id == 1) {
        if (min) {
          if (index == 1)
            next_index = 0
          else if (index == 2)
            next_index = 1
        } else {
          if (index == 0)
            next_index = 1;
          else if (index == 1)
            next_index = 2;
        }
        if (this.ranges[next_index]) {
          if (min) {
            this.thresholds[next_index]._max = threshold._min;
          } else {
            this.thresholds[next_index]._min = threshold._max;
          }
        }
      }
    },    
    show(info, title) {
      this.title = title;
      this.info = info;
      this.device = info.device;
      this.site = info.site;
      this.type = info.type;
      this.id = info.id;

      if (_.isUndefined(this.device.threshold)) this.device.threshold = []
      var device_thresholds = this.filteredItems(this.device.threshold, info.type, info.id)
      this.thresholds = _.isEmpty(device_thresholds) ? 
        _.cloneDeep(this.filteredItems(info.thresholds, info.type, info.id)) :
        device_thresholds;
      this.ranges = _.cloneDeep(this.thresholds)
      this.showModal = true

      const level0 = _.find(this.thresholds, {sensor_type : 32, level : 0 }).min; //0  -- 최소값 정상
      const level2 = _.find(this.thresholds, {sensor_type : 32, level : 2 }).min; //3  -- 최소값 주의
      const level3 = _.find(this.thresholds, {sensor_type : 32, level : 3 }).min; //5  -- 최소값 위험
      const level4 = _.find(this.thresholds, {sensor_type : 32, level : 3 }).max; //5  -- 최소값 위험
      
      this.value[0] = level0;
      this.value[1] = level2;
      this.value[2] = level3;
      this.value[3] = level4;

      var checkSensor = this.$parent.sensor_weight;
      var sensorColor = "#198754";
      var sensor_text = "정상";

      if(checkSensor > level3 ) {
          sensorColor = "red";
          sensor_text = "위험";
      } else if(checkSensor > level2 ) {
          sensorColor = "#fd7e14";
          sensor_text = "주의";
      } else{
          sensor_text = "정상";
      } 
      this.weight = "<span style='font-weight: bold; color: " + sensorColor + "'> "+ sensor_text +"<h4>(" + checkSensor + ")</h4></span><br>"
    }     
  }
}
</script>

<style>
  .merge-tooltip {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%, -15px);
      text-align: center;
  }
  .labels {
      text-align: center;
      display: block;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      font-weight: bold;
  }
</style>