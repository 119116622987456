<template>
  <div class="myChart">
    <div v-if="isShow">
      <CCard>
        <div class="card-header">
          <FontAwesomeIcon :icon="icon" aria-hidden="true"/> {{title}}
        </div>

        <div v-if="isEular"> 
          <div id="sensor_date" style="text-align: right;"/>
          <div class="row" style="justify-content: center; display: flex;">
              <EularAngular
                ref="eularAngularStandard"
                :device_guid.sync="device_guid"
                :content="content"
                :isStandard="true"
                :timeout="300"
                style="width: 220px; height: 200px;"
              />               
              <EularAngular
                ref="eularAngular"
                :device_guid.sync="device_guid"
                :content="content"
                :isStandard="false"
                :timeout="300"
                style="width: 220px; height: 200px;"
              />
          </div>
          <v-chart
            ref="test"
            autoresize
            :options="options"
            style="width: 100%; height: 268px;"
          />
        </div>
        <v-chart v-else
          ref="test"
          autoresize
          :options="options"
          style="width: 100%; height: 500px;"
        />

      </CCard>
    </div>
  </div>

</template>

<script>
import Chart from '@/views/device/Chart'

export default {
  name: 'ChartExt',
  extends: Chart,
}
</script>
