<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Component Settings Dialog"
    color="info"
    size="lg"
    @update:show="updateShow"
  >

    <template #header>
      <h6 class="modal-title text-center">이미지 모음</h6>
    </template>

    <div class='modal-body'>
      <div class="uploader">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>섬네일</th>
                <th>이름</th>
                <th>크기</th>
                <th>상태</th>
                <th>편집</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!files.length">
                <td colspan="9">
                  <div class="text-center p-5">
                    <h4>이미지 파일 드래그.<br/> - </h4>
                    <label :for="name" class="btn btn-lg btn-primary">파일 선택</label>
                  </div>
                </td>
              </tr>
              <tr v-for="(file, index) in files" :key="file.id">
                <td>{{index}}</td>
                <td>
                  <img class="td-image-thumb" v-if="file.thumb" :src="file.thumb" />
                  <span v-else>이미지 없음</span>
                </td>
                <td>
                  <div class="filename">
                    {{file.name}}
                  </div>
                  <div class="progress" v-if="file.active || file.progress !== '0.00'">
                    <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
                  </div>
                </td>
                <td>{{formatSize(file.size)}}</td>

                <td v-if="file.error">{{file.error}}</td>
                <td v-else-if="file.success">success</td>
                <td v-else-if="file.active">active</td>
                <td v-else></td>
                <td>
                  <CButton color="success"
                      :class="{disabled: file.active || file.success || file.error === 'compressing' || file.error === 'image parsing'}"
                      @click.prevent="file.active || file.success || file.error === 'compressing' ? false :  onEditFileShow(file)" >편집</CButton>
                </td>
                <td>
                  <CButton color="warning" @click.prevent="$refs.upload.remove(file)" >삭제</CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div class="btn-group">
          <sip-file-upload
            class="btn btn-primary"
            :name="name"
            :post-action="upload_url"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :size="size || 0"
            :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
            :headers="headers"
            :data="data"
            :drop="drop"
            :add-index="addIndex"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload">
            <i class="fa fa-plus"></i>
            파일 선택
          </sip-file-upload>
        </div>
        <CButton color="success" class="float-right"
            v-if="!$refs.upload || !$refs.upload.active" @click.prevent="startUpload">
            <FontAwesomeIcon :icon="['fa', 'arrow-up']" aria-hidden="true"/>업로드
        </CButton>
      </div>

      <hr>

      <div>
        <h4>[파일 리스트]</h4>
        <p></p>

        <div class="d-md-down-none" style="width:100%; max-height:300px; overflow:auto">
          <table class="table">
            <tbody>
              <tr v-for="(file, index) in deviceImages" :key="index">
                <td>
                  <img :src="file.url" exif="true" class="img-thumbnail" />
                </td>
                <td>
                <td>
                  <CButton color="danger" @click="deleteFile(index, file)" title="삭제"><span class="glyphicon glyphicon-trash"></span> 삭제</CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-lg-none" style="width:100%; max-height:200px; overflow:auto">
          <table class="table">
            <tbody>
              <tr v-for="(file, index) in deviceImages" :key="index">
                <td>
                  <img :src="file.url" class="img-thumbnail" />
                </td>
                <td>
                <td>
                  <CButton color="danger" @click="deleteFile(index, file)" title="삭제"></CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>
    <Editor
        ref="editor"
        :editFile.sync="editFile"
        @save="onUpdateImage"
    />

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';

import ImageCompressor from '@xkeshi/image-compressor'
import Editor from '@/views/device/Editor'

export default {
  name: 'Uploader',
  components: {
    // FileUpload,
    Editor
  },
  props: {
    storage: {
      type: String,
      default: 'storages'
    },
    device_guid: String
  },
  data() {
    return {
      showModal: false,
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      addIndex: false,
      thread: 3,
      name: 'image_uploader',
      headers: {
        'X-Csrf-Token': 'xxxx',
      },
      data: {
        '_csrf_token': 'xxxxxx',
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      editFile: {
        show: false,
        name: '',
      },
      upload_url: '',
      deviceImages: [],
      upload_count: 0,
      need_refresh: false
    }
  },
  computed: {
  },
  created: function() {
    this.upload_url = '{0}/{1}/{2}/upload'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
    // console.log('Uploader::created upload_url:', this.upload_url)
  },
  watch: {
    storage: function(new_val, old_val) {
      // console.log('Uploader::watch storage - ', new_val)
      this.upload_url = '{0}/{1}/{2}/upload'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
      // console.log('Uploader::watch storage upload_url:', this.upload_url)
    }
  },
  methods: {
    formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        // Automatic compression
        if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor.compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, { error: err.message || 'compress' })
            })
        }
      }
      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
      // image size
      if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        newFile.error = 'image parsing'
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
        } 
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: 'parsing image size'}) 
        }
        img.src = newFile.blob
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: 'size' })
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          this.fileUploaded(newFile)
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    alert(message) {
      alert(message)
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true }
      // this.$refs.upload.update(file, { error: 'edit' })
      this.$refs.editor.show(this.editFile)
    },
    onUpdateImage(event) {
      // console.log('Uploader::onUpdateImage event:', JSON.stringify(event))
      this.$refs.upload.update(this.editFile.id, event.data)
      this.editFile.error = ''
      this.editFile.show = false
    },
    startUpload() {
      this.upload_url = '{0}/{1}/{2}/upload'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
      console.log('Uploader::startUpload upload_url:', this.upload_url)
      this.$refs.upload.active = this.need_refresh = true;
    },
    fileUploaded(file) {
      if (++this.upload_count == this.files.length) {
        this.upload_count = 0;
        this.getDeviceImages()
          .then(res => {
            this.deviceImages = res
          })
          .catch(err => {
            console.log('Uploader::show err:', err.toString())
          })
      }
    },
    deleteFile(index, file) {
      var url = file.url.replace('download', 'files');
      this.need_refresh = true
      loopback
        .delete(url)
        .then(res => {
          this.deviceImages.splice(index, 1)
        })
    },
    getDeviceImages() {
      var self = this;
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);

      var path = '{0}/{1}/files'.format(this.storage, this.device_guid);
      return loopback
        .get(path)
        .then(res => {
          var data = _.sortBy(res, ['ctime']).reverse(); 
          var deviceImages = [];

          data.forEach(function(file) {
            deviceImages.push({
              url: download_url + '/' + file.name
            })
          })
          return Promise.resolve(deviceImages)
        })
        .catch(err => {
          console.log('Uploader::getDeviceImages Error: ', err.toString())
        })
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: 'update',
        data: { need_refresh : this.need_refresh }
      }
      this.$emit('hide', msg);

      this.showModal = false
    },
    show() {
      this.files = []
      this.getDeviceImages()
        .then(res => {
          this.deviceImages = res
          this.showModal = true
        })
        .catch(err => {
          console.log('Uploader::show err:', err.toString())
        })
    },
    updateShow(beShow, event, accept) {
      if (beShow === false) {
        this.cancel()
      }
    }
  }
}
</script>

<style>
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #e4e5e6;
  border: 1px solid #ddd;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}
</style>
