<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}} 기준 설정</h6>
    </template>

    <div class='modal-body'>
      <div class="gaugeForm">
        <CRow>
          <CCol col="12" xl="6">
            <Gauge class="xGauge" title="전ㆍ후방 현재 기울기(x축)" :value="this.sensorDiffX()" :thresholds="this.thresholds" />
          </CCol>
          <CCol col="12" xl="6">
            <Gauge class="yGauge" title="좌ㆍ우측 현재 기울기(y축)" :value="this.sensorDiffY()" :thresholds="this.thresholds" />
          </CCol>
        </CRow>
      </div>
      <form name='thresholdForm'>
        <div class='form-group'>
          <div class='row d-sm-down-none'>
            <div class='col-sm-3 col-lg-3'>
              <label>상태</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최소값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최대값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>푸쉬알림</label>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <div class="row" v-for="(threshold, index) in thresholds" :key="index">
            <div class='col-sm-3 col-lg-3'>
              <input type='text' class='form-control text-center' :style="{'background-color': threshold.color, 'font-weight': 'bold'}" v-model='threshold.name' readonly>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._min' @change='changeRange(index, threshold, true)' :step=0.1>
            </div>

            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._max' @change='changeRange(index, threshold, false)' :step=0.1>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <CSwitch class="mr-1" color="info" :checked.sync="threshold.is_push" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';
import Threshold from '@/views/device/Threshold'
import Gauge from "@/views/srds/device/Gauge"

export default {
  name: 'SRDSThreshold',
  extends: Threshold,
  components: {
    Gauge
  },
  methods: {
    sensorDiffX() {
      console.warn = console.error = () => {};

      let standard = _.get(this.device, "standard");

      /* 현재값 */
      let xData = _.get(_.find(this.device.sensor.data, { type: 8, id: 1 }), 'value', null);
      
      /* 차이값 */
      let xDiff = standard.x - xData;
      return xDiff
      
    },
    sensorDiffY() {
      console.warn = console.error = () => {};
      
      let standard = _.get(this.device, "standard");

      /* 현재값 */
      let yData = _.get(_.find(this.device.sensor.data, { type: 8, id: 2 }), 'value', null);
      
      /* 차이값 */
      let yDiff = standard.y - yData;
      return yDiff
    },
    changeRange(index, threshold, min) {
      var next_index = -1;
      if ((threshold.sensor_type == 8)&&threshold.sensor_id == 1) {
        if (min) {
          if (index == 1)
            next_index = 0
          else if (index == 2)
            next_index = 1
        } else {
          if (index == 0)
            next_index = 1;
          else if (index == 1)
            next_index = 2;
        }
        if (this.ranges[next_index]) {
          if (min) {
            this.thresholds[next_index]._max = threshold._min;
          } else {
            this.thresholds[next_index]._min = threshold._max;
          }
        }
      }
    },  
    register() {
      var self = this
      var default_include = []
      var exclude = this.filteredMatchItems(false, this.device.threshold, this.service_type, this.type);
      var include = this.filteredMatchItems(true, default_include, this.service_type, this.type);

      var where = {
        _id: this.device.guid
      }
      var data = {
        threshold: exclude.concat(this.thresholds, include)
      }
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices',where:where,data:data})
        .then(res => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.sendthresholdToDevice()
//          console.log("===============> ",this.thresholds)
          this.$emit('update', msg)
          this.showModal = false;
        })      
        .catch(err => {
          console.log('Threshold::register error:'. err.toString())
          this.showModal = false;
        })
    },
    sendthresholdToDevice() {
      let standard = _.get(this.device, "standard");
      var data = {
        command: 'exec',
        device_guid: this.device.guid,
        params: [{
          cmd: 'set',
          data: {
            x: standard.x,
            y:standard.y,
            diff: this.thresholds[0].max
          }
        }]
      }
      console.log('Threshold::sendthresholdToDevice params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Threshold::sendthresholdToDevice res: ', res)
        })
        .catch(err => {
          console.log('Threshold::sendthresholdToDevice error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })
    },
  }
}
</script>

<style scoped lang="scss">
.modal-body {
  .gaugeForm {
    display: flex;
  }
  .form-group {
    margin-left: 10%;
  }
}

</style>