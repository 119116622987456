<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}} 설정</h6>
    </template>

    <div class='modal-body'>
      <form name='thresholdForm'>
        <div class="row">
            <div class="col-sm-6 col-lg-6">
              <label>전ㆍ후방 값(x축)</label>
              <input type='number' id='set_angle_x' class='form-control' placeholder='0.0' v-model='standard.x' :step='.1'>
            </div>
            <div class="col-sm-6 col-lg-6">
              <label>좌ㆍ우측 값(y축)</label>
              <input type='number' id='set_angle_y' class='form-control' placeholder='0.0' v-model='standard.y' :step='.1'>
            </div>
            <!-- <div class="col-sm-4 col-lg-4">
              <label>z축 값</label>
              <input type='number' id='set_angle_z' class='form-control' placeholder='0.0' v-model='standard.z' :step='.1'>
            </div>           -->
        </div>
        <div class="search-margin"></div>
      </form>
    </div>

    <div class="table">
      <div class="tableHeader">
        <div class="headerItem">기울기 측정일</div>
        <div class="headerItem">전ㆍ후방 기울기(x축)</div>
        <div class="headerItem">좌ㆍ우측 기울기(y축)</div>
        <!-- <div class="headerItem">z축 기울기</div> -->
        <div class="headerItem">
          <CButton class="btn" color="info" @click="setInclineData">데이터 조회</CButton>
        </div>
      </div>
      <div v-for="(item, index) in inclineDataSet" :key="index" class="tableContent">
        <transition name="fade">
          <div v-if="item.isActive" class="contentWrapper">
            <div class="content">{{ item.created_at }}</div>
            <div class="content">{{ item.x_value }}º</div>
            <div class="content">{{ item.y_value }}º</div>
            <!-- <div class="content">{{ item.z_value }}º</div> -->
            <div class="content"><CButton class="btn" color="success" @click="setStandardValue(item)">{{ toggle }}</CButton></div>
            <!-- <div class="content">
              <label class="c-switch c-switch-success">
                <input type="checkbox" class="c-switch-input" >
                <span class="c-switch-slider"></span>
              </label>
            </div> -->
          </div>
        </transition>
      </div>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>


<script>
import moment from "moment";
import Confirm from "@/views/popups/Confirm";
export default {
  name: 'Incline',
  extends: Confirm,
  data() {
    return {
      standard: {
        x: 0.0,
        y: 0.0,
        z: 0.0
      },
      toggle: '선택',
      deviceIncline: [],
      inclineDataSet: [],
      device: {},   
    }
  },
  methods: {
    dateFormatter(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    /** Sensor 데이터 가져오기 */
    getdeviceIncline() {
      var self = this;
      // this.deviceIncline = {}
      var filter = {
        where: {
          and: [{
            device_guid: self.device.guid
          }]
        },
        order: 'created_at desc',
        limit: 5,
      }
      self.$store.dispatch('rest/find', {model:'sensors', filter: filter})
        .then(res => {
          this.deviceIncline = res
          this.setInclineData()
        })

    },
    /** 테이블 작성 */
    setInclineData() {
      
      let inclineData = []

      // standard값(event)을 반영할 것인지 sensor_value값(sensor)을 사용하는 것이 맞는지?
      // 그 동안 standard값을 한번 정해 놓고 바꾸지 않은 것 같아서 계속 일정하다
      // 기준값설정인데 그 동안의 설정된 기준값을 보여주는 것이 맞지 않나???
      // 생각보다 기준값을 바꾸는일이 잦은가?? -> 별로 없다면 보여줄만한 데이터도 없고 의미도 없어지는 것 같다

      for(let data of this.deviceIncline) {
        let incline = {}
        incline.created_at = this.dateFormatter(data.created_at)
        incline.isActive = true
        for(let item of data.data) {
          if(item.type === 8) {
            switch (item.id) {
              case 1:
                incline.x_value = item.value
                break;
              case 2:
                incline.y_value = item.value
                break;
              case 3:
                incline.z_value = item.value
                break;
              default:
                break;
            }
            if(Object.keys(incline).includes('z_value')) {
              inclineData.push(incline)
              incline = {}
            }
          }
        }
      }
      this.inclineDataSet = inclineData
    },
    /** 선택버튼클릭 후 데이터 반영 */
    setStandardValue(item) {
      this.standard.x = item.x_value
      this.standard.y = item.y_value
      this.standard.z = item.z_value
      
      item.isActive = false
    },
    register() {
      var where = {
        _id: this.device.guid
      }
      var data = {
        standard: this.standard
      }
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices',where:where,data:data})
        .then(res => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.showModal = false;
        })      
        .catch(err => {
          console.log('Threshold::register error:'. err.toString())
          this.showModal = false;
        })
    },
    show(info, title) {
      this.title = title;
      this.device = info.device;
      this.showModal = true;
      this.getdeviceIncline();
    },
  },
  watch: {
    device: {
      deep: true,
      handler(val) {
        this.standard = this.device.standard
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {    
  .tableHeader {
    display: flex;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid gray;
    .btn {
      // background-color: green;
      color: #fff;
    }
    .headerItem {
      width: 25%;
      color: gray;
      line-height: 40px;
      text-align: center;
    }
  }
  .tableContent {
    background-color: #f2f2f2;
    &:hover {
      border-radius: 0px 10px 10px 0px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(33,33,33,.2);
      border-left: 4px solid #2EB85C;
    }
    .contentWrapper {
      height: 50px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      .content {
        width: 25%;
        line-height: 50px;
      }
      .btn {
        // background-color: #23ADC6;
        color: #fff;
      }
    }
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

</style>