<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create User Dialog"
    color="info"
    size="lg"
  >

    <template #header>
      <h6 class='modal-title text-center'>{{ title }}</h6>
    </template>

    <div class='modal-body'>
      <form name='deviceForm'>
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <label>시리얼번호 <strong class='text-danger'>*</strong></label>
            <input type='text' class='form-control' placeholder='Enter the id of device' v-model='serialNo' :required='true' :disabled='true'>
          </div>
          <div class="col-sm-6 col-lg-6">
            <label>명칭 </label>
            <input type='text' class='form-control' placeholder='Enter the name of device' v-model='device.name'>
          </div>
        </div>

        <div class="search-margin"></div>

        <div class="row">
          <div class="col-sm-8 col-lg-8">
            <label><strong class='text-danger'>데이터 업로드 주기</strong> </label>
              <select id = "upload_cron" @blur='cronValidate("upload_cron", "cronString", "full")' :placeholder='cronDescription' class='form-control' v-model='cronString'>
                <option v-for="cronString in cronStringTypes" :value="cronString.value" v-bind:key="cronString.id">{{cronString.name}} </option>
              </select>
          </div>
          <div class="col-sm-4 col-lg-4" v-if='!isMobile'>
            <label><strong class='text-danger'>데이터 업로드시 사진 전송여부 </strong></label>
            <br>
            <CSwitch class="mr-1" color="info" v-bind="oLabelTxt" :checked.sync="periodic_pic" size="sm"/>
          </div>
         <!-- <div class="col-sm-2 col-lg-2" v-if='!isMobile && isAdmin'>
            <label>장비사용여부 </label>
            <br>
            <CSwitch class="mr-1" color="info" v-bind="oLabelTxt" :checked.sync="device_active" size="sm" :disabled='isAdmin == false'/>
          </div>-->
        </div>
        <div class="search-margin"></div>
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item navbar-custom">
                <a tab class="nav-link ng-binding active" data-toggle="tab" id=' #tab0' href='#tab0' role="tab">위치 변경</a>
              </li>
              <!--<li class="nav-item navbar-custom ">
                <a tab class="nav-link ng-binding" data-toggle="tab" id='#tab1' href='#tab1' role="tab">설치 기준값 설정</a>
              </li>-->           
            </ul>

            <div class="tab-content">
              <div class="tab-pane active" id='tab0' role="tabpanel">
                <div class="search-margin"></div>         
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <label>주소</label>
                    <input type='text' id='input_address' class='form-control' placeholder='Enter the address of device' v-model='device.address'>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <label>설치 일자</label>
                    <div class='input-group'>
                      <date-picker v-model="install_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" style="width:100%;"></date-picker>
                    </div>
                  </div>

                </div>
                <div class="search-margin"></div>

                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <label>위도</label>
                    <input type='text' id='input_latitude' class='form-control' placeholder='위도' v-model='device.latitude'>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <label>경도</label>
                    <input type='text' id='input_longitude' class='form-control' placeholder='경도' v-model='device.longitude'>
                  </div>
                </div>
                <div class="search-margin"></div>
                <div class="row apps-container" v-show="user_service_type!='military'">
                  <div class="col-sm-12 col-lg-12">
                    <GoogleMap ref="googleMap" :sensor_type="8" :sensor_level="1" :device_guid="device.guid" @latLng="onGeoCode" style="min-height:100%;padding-top:0.1%;padding-bottom:0.1%;" />
                  </div>
                </div>

              </div>

              <!--<div class="tab-pane" id='tab1' role="tabpanel">
                <div class="row">
                </div>
                <div class="search-margin"></div>
                <div class="row">
                    <div class="col-sm-4 col-lg-4">
                      <label>x축 값</label>
                      <input type='number' id='set_angle_x' class='form-control' placeholder='0.0' v-model='standard.x' :step='.1'>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <label>y축 값</label>
                      <input type='number' id='set_angle_y' class='form-control' placeholder='0.0' v-model='standard.y' :step='.1'>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <label>z축 값</label>
                      <input type='number' id='set_angle_z' class='form-control' placeholder='0.0' v-model='standard.z' :step='.1'>
                    </div>          
                </div>
                <div class="search-margin"></div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class='form-group'>
                      <label>설명</label>
                      <textarea class='form-control' rows='2' placeholder='' v-model='device.description'></textarea>
                    </div>
                  </div>
                </div>

              </div>-->

            </div>

          </div>
        </div>

      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
  
    <Confirm
        ref="confirmDialog"
        title="확인"
        which="update"
        @hide="hideModal"
    />

    <Notify ref="notifyDialog"/>

  </KModal>

</template>

<script>
import moment from 'moment';

import DeviceInfo from '@/views/device/DeviceInfo'
import GoogleMap from '@/views/srds/GoogleMap'

export default {
  name: 'DeviceInfoExt',
  extends: DeviceInfo,
  components: {
    GoogleMap,
  },
  data () {
    return {
      standard: {
        x: 0.0,
        y: 0.0,
        z: 0.0
      },
      work_schedule_: [],
      periodic_pic: false,
      user_service_type: this.$store.state.auth.user_info.user.service_type
    }
  },
  watch: {
    device: {
      deep: true,
      handler(val) {
        this.serialNo = 'SN-' + this.device.guid;
        this.install_date = new Date(this.device.install_date);
        this.device_active = this.device.status === 0;
        this.network_module = this.device.network_module;
        this.standard = this.device.standard;
        this.cronString = this.device.upload_cron ? this.device.upload_cron.slice(2) : '';
        this.periodic_pic = this.device.periodic_pic_active === 0;
      }
    },
  },
  methods: {
    hideModal(event) {
      if (event.data !== 'confirm' || event.which !== 'update') {
        return; // cancel
      }

      var where = {
        guid: this.device.guid
      }

      var data = {
        upload_cron: '',
        name: this.device.name,
        description: this.device.description,
        address: this.device.address,
        latitude: this.device.latitude,
        longitude: this.device.longitude,
        standard: this.standard,
        status: this.device_active ? 0 : 1,
        periodic_pic_active: this.periodic_pic ? 0 : 1
      }

      if (!_.isEmpty(this.cronString)) {
        data.upload_cron = '0 ' + this.cronString;
      }
      else{
        data.upload_cron = this.device.upload_cron;
      }
      data.install_date = moment(this.install_date).utc().format('YYYY-MM-DD HH:mm:ss')
      
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices', where:where, data:data})
        .then(res => {
          // console.log('DeviceInfo upsert OK: ', res)
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.showModal = false
        })
        .catch(err => {
          console.log('DeviceInfo upsert Err: ', err.toString())          
          this.$refs.notifyDialog.show(err.toString())
          this.showModal = false
        })

    }
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>