<template>
  <HistoryMeasure
    ref=historyMeasure
    :device_guid.sync="device_guid"
    :device.sync="device"
  />
</template>

<script>
import History from '@/views/device/History';
import HistoryMeasure from '@/views/srds/device/HistoryMeasure';

export default {
  name: 'HistoryExt',
  extends: History,
  components: {
    HistoryMeasure,
  },
}
</script>