<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}} 기준 설정</h6>
    </template>

    <div class='modal-body'>
      <form name='thresholdForm'>
        <div class='form-group'>
          <div class='row d-sm-down-none'>
            <div class='col-sm-3 col-lg-3'>
              <label>상태</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최소값 <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최대값 <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>푸쉬알림</label>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <div class="row" v-for="(threshold, index) in thresholds">
            <div class='col-sm-3 col-lg-3'>
              <input type='text' class='form-control text-center' :style="{'background-color': threshold.color, 'font-weight': 'bold'}" v-model='threshold.name' readonly>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 v-model='threshold._min' @change='changeRange(index, threshold, true)' :step='step'>
            </div>

            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 v-model='threshold._max' @change='changeRange(index, threshold, false)' :step='step'>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <CSwitch class="mr-1" color="info" :checked.sync="threshold.is_push" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';

export default {
  name: 'Threshold',
  props: {
    isAdmin: Boolean
  },
  data () {
    return {
      showModal: false,
      title: '생성',
      which: 'create',
      info: {},
      device: {},
      service_type: 'sis',
      site: {},
      thresholds: [],
      type: -1,
      id: -1,
      step: 1,
      ranges: [],
      isEditMode: false
    }
  },
  methods: {
    changeRange(index, threshold, min) {
      var next_index = -1;
      if (threshold.sensor_type == 1) {
        next_index = min ? index + 1 : index - 1;
        if (this.ranges[next_index]) {
          if (min) {
            this.thresholds[next_index]._max = threshold._min;
          } else {
            this.thresholds[next_index]._min = threshold._max;
          }
          // console.log("####### battery update:", next_index, ":", this.thresholds[next_index]);
        }
      } else if ((threshold.sensor_type == 2 || threshold.sensor_type == 12)&&threshold.sensor_id == 1) {
        if (min) {
          if (index == 0)
            next_index = 2;
          else if (index == 2)
            next_index = 1
          else if (index == 3)
            next_index = 0
        } else {
          if (index == 0)
            next_index = 3;
          else if (index == 1)
            next_index = 2;
          else if (index == 2)
            next_index = 0;
        }

        if (this.ranges[next_index]) {
          if (min) {
            this.thresholds[next_index]._max = threshold._min;
          } else {
            this.thresholds[next_index]._min = threshold._max;
          }
          // console.log("####### humidity update:", next_index, ":", this.thresholds[next_index]);
        }
      }
    },
    filteredItems(items, type, id) {
      var r = items.filter(function(item) {
        if (item.sensor_type === type && item.sensor_id === id) {
          var name = item.name;
          var new_name = name.split("-").map(function(val) {
            return val
          });
          item.name = new_name && new_name.length > 1 ? new_name[1] : new_name[0];
          item._max = item.max;
          item._min = item.min;
          return true;
        }
      })
      return r;
    },
    filteredMatchItems(match, items, service_type, sensor_type) {
      if (!items) {
        return [];
      }
      var r = items.filter(function(item) {
        if (item.service_type === service_type) {
          item.min = item._min ? item._min : item.min;
          item.max = item._max ? item._max : item.max;

          if (match){
            if((item.sensor_type == 19) || (item.sensor_type == 12 && item.sensor_id == 3)){
              return item.sensor_type !== sensor_type;
            }
            return item.sensor_type == sensor_type;
          }
          else{
            if((item.sensor_type == 19) || (item.sensor_type == 12 && item.sensor_id == 3)){
              return item.sensor_type == sensor_type;
            }
            return item.sensor_type !== sensor_type;
          }
        }
      })
      return r;
    },
    register() {
      var self = this
      var default_include = []
      // var sensor19 = _.find(this.info.thresholds, { sensor_type: 19 })
      // if (sensor19) default_include.push(sensor19)
      // var sensor12 = _.find(this.info.thresholds, { sensor_type: 12, sensor_id: 3 })
      // if (sensor12) default_include.push(sensor12)
      var exclude = this.filteredMatchItems(false, this.device.threshold, this.service_type, this.type);
      var include = this.filteredMatchItems(true, default_include, this.service_type, this.type);

      var where = {
        _id: this.device.guid
      }
      var data = {
        threshold: exclude.concat(this.thresholds, include)
      }
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices',where:where,data:data})
        .then(res => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.showModal = false;
        })      
        .catch(err => {
          console.log('Threshold::register error:'. err.toString())
          this.showModal = false;
        })
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show(info, title) {
      this.title = title;
      this.info = info;
      this.device = info.device;
      this.site = info.site;
      this.type = info.type;
      this.id = info.id;

      if (_.isUndefined(this.device.threshold)) this.device.threshold = []
      var device_thresholds = this.filteredItems(this.device.threshold, info.type, info.id)
      this.thresholds = _.isEmpty(device_thresholds) ? 
        _.cloneDeep(this.filteredItems(info.thresholds, info.type, info.id)) :
        device_thresholds;
      this.ranges = _.cloneDeep(this.thresholds)
      this.showModal = true
    }
  }
}
</script>
