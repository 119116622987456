<template>
  <CRow>
    <CCol col="12" xl="12">
      <Search
          :dates="dates" 
          :date_type.sync="date_type" 
          :is_measures="true" 
          :enable_excel.sync="enable_excel"
          @search="searchStat"
          @save="saveMeasurement()"
      />
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="card-columns cols-2">
            <Chart 
                ref="echartComponent"
                v-for="(component, index) in device_components"
                :key="index"
                :content="component"
                :thresholds="thresholds"/>
          </div>
        </div>
      </div>

      <div class="row" v-if="device_components.length == 0">
        <div class="col-sm-12 col-lg-12">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title"><strong>{{content_name}}</strong></h5>
          </div>
          <div class="card-footer">
            <h6 class="card-text">{{message}}</h6>
          </div>
        </div>
        </div>
      </div>

      <Progress
          ref="progressDialog"
          title="확인"
      />

      <Notify ref="notifyDialog"/>

    </CCol>
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import XLSX from 'xlsx';
import utils from '@/services/utils';

import EventBus from '@/services/EventBus'
import Search from '@/views/device/Search';
import Chart from '@/views/device/Chart';

import { DATE } from '@/constants/constant'
import Progress from '@/views/popups/Progress';


export default {
  name: 'HistoryMeasure',
  components: {
    Search,
    Chart,
    Progress
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object,
      default: {}
    },
    site_guid : {
      type: String,
      default: undefined
    },
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;

    if (this.site_guid) {
      var site = _.find(this.userInfo.sites, {guid:this.site_guid})
      this.content_name = site.name
    } else {
      this.content_name = this.device.name
    }
    utils.loadThresholds(res => this.thresholds = res)

    this.getDashboardComponent();
    this.getStat();
  },
  data () {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var from = new Date(today.setTime(today.getTime() - 7 * 86400000))
    var to = new Date(now.setTime(now.getTime() + 86400000))

    // from = new Date('2019-01-01'); // test

    return {
      isAdmin: this.$store.state.auth.is_admin,
      date_type: DATE.TYPE.DAILY,
      stat_data: [],
      dates: [from, to],
      enable_excel: false,
      device_components: [],
      dashboard_components: [],
      thresholds: [],
      message: '조회중입니다.',
      content_name: ''
    }
  },
  methods: {
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      return this.dates;
    },   
    searchStat(dates, date_type) {
      this.dates = dates;
      this.date_type = date_type;
      this.device_components = []
      this.getStat()
      EventBus.$emit('reload')
    },
    makeFileName(name) {
      var from = moment(this.dates[0]).format('YYYY-MM-DD');
      var to = moment(this.dates[1]).format('YYYY-MM-DD');
      return name + "_" + from + "-" + to;
    },
    saveMeasurement() {
      var self = this;
      if (_.isEmpty(this.stat_data)) {
        this.$refs.notifyDialog.show('검색된 데이터가 없습니다.');
        return;
      }

      function makeSheet(series) {
        var r = []
        for (var i = 0; i < series.length; i++) {
          var { data, name } = series[i];
          for (var j = 0; j < data.length; j++) {
            if (r.length <= j) r.push({})
            var row = r[j];
            row['측정일'] = data[j][0];
            row[name] = data[j][1];
          }
        }
        return XLSX.utils.json_to_sheet(r)
      }

      var write = function() {
        var wscols = [{
          wch: 20
        }];

        var wb = XLSX.utils.book_new();
        var prefix = '측정이력'

        _.filter(self.$refs.echartComponent, function(echart, index) {
          var sheet = makeSheet(echart.options.series)
          sheet['!cols'] = wscols;
          var title = Number(echart.options.type) !== 8 ? utils.getSensorTypeName(echart.options.type) : '9Axis_'+index;
          title = title.replace('/', '-')
          XLSX.utils.book_append_sheet(wb, sheet, title);
        })

        var filename = self.makeFileName(prefix);
        XLSX.writeFile(wb, filename + ".xlsx");
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    },
    buildStatData (data, date_type, isSave) {
      var result = {}

      var isSave = isSave || false;
      data.forEach(function(e) {
        if (!result[e.device_guid])
          result[e.device_guid] = []

        var date = e.created_at;
        var row = {
          created_at: e.created_at
        }
        if (isSave) {
          var fmt = date_type === DATE.TYPE.DAILY ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
          row.created_at = moment(e.created_at).format(fmt);
        }

        var r = [];
        r.push(e.created_at);

        e.data.forEach(function(d) {
          if(d.type === 35 || d.type === 36)
             return;
          var fixed_value = d.type === 14 ? d.value.toFixed(4) : d.value.toFixed(2);
          if (r[d.type]) {
            if (!_.isArray(r[d.type])) {
              r[d.type] = [r[d.type]]
            }
            r[d.type].push(fixed_value)
          } else {
            r[d.type] = fixed_value;
          }
        })

        result[e.device_guid].push(r);
      });
      return result;
    },
    getSiteGuid() {
      if (!_.isUndefined(this.site_guid)) {
        return Promise.resolve(this.site_guid)
      }
      var filter = {
        where: {
          guid: this.device_guid
        },
        fields: {
          site_guid: true
        }
      }
      return this.$store.dispatch('rest/find', {model:'devices',filter:filter})
        .then(device => {
          return Promise.resolve(device[0].site_guid)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    getDashboardComponent() {
      var filter = {
        where: {
          user_guid: this.userInfo.user.guid
        }
      }
      this.$store.dispatch('rest/find', {model:'dashboards',filter:filter})
        .then(res => {
          var dashboard = _.first(res);
          if (!dashboard) return;
          this.dashboard_components = dashboard.components
          this.dashboard_components.forEach(el => {
            if (_.isArray(el.icon)) {
              return el
            }
            el.icon = _.map(el.icon.split(' '), el => {
              el = el.replace('line-chart', 'chart-line')
              el = el.replace('exchange', 'exchange-alt')
              el = el.replace('humidity', 'cloud-rain')
              return el.replace('fa-', '')
            })
          })
        })
        .catch(err => {
          console.log('History::getComponent error: ', err.toString())
        })
    },
    getDevices(site_guid) {
      var data = {
        site_guid: [site_guid],
      }
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          var rows = [];
          res.forEach(function(d, i) {
            d.data.forEach(function(e) {
              rows[e.guid] = {
                site_guid: d.Site.guid,
                name: e.name
              }
            })
          })
          return Promise.resolve(rows)
        })
    },
    getStat() {
      $("body").css("cursor", "progress");

      var where = {
        date_type: this.date_type,
        from: moment(this.dates[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
        to:   moment(this.dates[1]).utc().format('YYYY-MM-DD HH:mm:ss'),
        site_guid: undefined,
        device_guid: this.device_guid,
      }
      this.enable_excel = false;
      var stat_data = undefined;
      this.getSiteGuid()
        .then(site_guid => {
          where.site_guid = site_guid
          return this.$store.dispatch('rest/method', {model:'stats',method:'getStat',data:where})
        })
        .then((res) => {
          stat_data = this.buildStatData(res.data, this.date_type)
          this.stat_data = stat_data;
          this.enable_excel = !_.isEmpty(stat_data);
          return this.getDevices(where.site_guid)
        })
        .then((infos) => {
          if (_.isEmpty(stat_data)) {
            this.message = '검색 결과가 존재하지 않습니다.';
          }
          this.dataProcessing(stat_data, infos)
          $("body").css("cursor", "default");
        })
        .catch((err) => {
          $("body").css("cursor", "default");
          console.log('History::getStat error:', err.toString())
        })
    },
    dataProcessing(datas, infos) {
      // console.log('History::dataProcessing stat_data:{0}, device_info:{1}'.format(JSON.stringify(datas), JSON.stringify(infos)))
      var self = this;
      var sensor_datas = {}
      
      _.filter(datas, function(device, guid) {

        if (!sensor_datas[guid])
          sensor_datas[guid] = {}

        _.filter(device, function(sensors, i) {

          var date_ = undefined;
          _.filter(sensors, function(value, type) {
            if (_.isUndefined(value)) return
            if (type === 0) { // sensors[0] : date format
              var fmt = self.date_type === DATE.TYPE.DAILY ? 'YYYY-MM-DD HH' : 'YYYY-MM-DD HH:mm:ss';
              date_ = moment(value).utc(32400).format(fmt);
              return;
            }

            if (!sensor_datas[guid][type]) 
              sensor_datas[guid][type] = {'0':[]}

            if (_.isArray(value)) {
              _.filter(value, function(_value_, id) {
                if (_.isUndefined(_value_)) return
                if (!sensor_datas[guid][type][id])
                  sensor_datas[guid][type][id] = []
                sensor_datas[guid][type][id].push([date_, _value_])
              })
            } else {
              sensor_datas[guid][type]['0'].push([date_, value])
            }
          }); // filter(sensors)
        }); // filter(device)
      }); // filter(datas)

      this.device_components = []
      
      var components = {}
      var eular_angular = [];
      _.filter(sensor_datas, function(device, guid) {
        _.filter(device, function(sensors, type) {
          var component = undefined;
          if (Number(type) === 8) { // Eular angular - roll, pitch, yaw
            component = eular_angular;
          } else {
            if (_.isUndefined(components[type])) {
              components[type] = []
            }
            component = components[type];
          }
          // console.log('History::dataProcessing type:{0}, sensor-ids:{1}'.format(type, Object.keys(sensors)))
          var data = {
            sort: infos[guid].name,
            enable: true,
            guid: guid,
            type: type,
            title: utils.getSensorTypeName(type) || 'Tech9 Sensor',
            icon: utils.getFontawesomeIcon(type),
            date_type: self.date_type,
            infos: infos,
            image_name: '통계',
            sensors: sensors,
            standard: self.device.standard
          }
          component.push(data)
        })
      })
      _.filter(components, function(data, type) {
        self.device_components.push(data)
      })
      eular_angular = _.sortBy(eular_angular, 'sort');
      _.filter(eular_angular, function(data) {
        self.device_components.push([data])
      })

    } // dataProcessing
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
body.busy-cursor {
  cursor: progress;
}
</style>